import React from 'react';
import './NavBar.css';

const NavBar = ({ multiplier, countdown, history }) => {
  const jackpotProgress = Math.floor(Math.random() * 4000); // Random value for jackpot
  const bonusProgress = Math.floor(Math.random() * 3000); // Random value for bonus
  const giftProgress = Math.floor(Math.random() * 2000); // Random value for gift

  const jackpotGoal = 4000;
  const bonusGoal = 3000;
  const giftGoal = 2000;

  return (
    <div className="navbar">
      <div className="navbar-top">
        <div className="navbar-logo">RA</div>
        <div className="navbar-history">
  {history.slice(0, 20).map((historyItem, index) => (
    <span
      key={index}
      className={`history-item ${
        historyItem >= 50
          ? 'history-gold'
          : historyItem >= 10
          ? 'history-blue'
          : historyItem >= 2
          ? 'history-green'
          : 'history-red'
      }`}
    >
      {historyItem.toFixed(2)}x
    </span>
  ))}
</div>

      </div>
      <div className="navbar-buttons">
        {/* Jackpot */}
        <div className="navbar-item jackpot">
          <p>Jackpot</p>
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${(jackpotProgress / jackpotGoal) * 100}%` }}
            ></div>
          </div>
          <span>{jackpotProgress}/{jackpotGoal}</span>
        </div>
        {/* Bonus */}
        <div className="navbar-item bonus">
          <p>Bonus</p>
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${(bonusProgress / bonusGoal) * 100}%` }}
            ></div>
          </div>
          <span>{bonusProgress}/{bonusGoal}</span>
        </div>
        {/* Gift */}
        <div className="navbar-item gift">
          <p>Gift</p>
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${(giftProgress / giftGoal) * 100}%` }}
            ></div>
          </div>
          <span>{giftProgress}/{giftGoal}</span>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
