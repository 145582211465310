import React from 'react';
import './App.css'; // Assuming your loader styles are here

const Preloader = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
};

export default Preloader;
