import React, { useState, useEffect, useRef, useMemo } from "react";
import { FaMouse } from "react-icons/fa"; // FontAwesome Mouse Icon
import PointerMoveHandler from "./PointerMoveHandler"; // Adjust the path as necessary

const PlayerPanel = React.memo(
  ({
    player = {},
    gameStatus = "Idle",
    multiplier = 1,
    index = 0,
    resetGame = false,
    placeBet = () => {},
    cashOut = () => {},
    setMouseOwner = () => {},
    setActiveMouse = () => {},
    isMultipleMiceEnabled = true, // This flag to check if multiple mice are enabled
  }) => {
    const [betAmount, setBetAmount] = useState(10); // Default bet amount
    const [finalWin, setFinalWin] = useState(0);
    const [balanceBeforeCashout, setBalanceBeforeCashout] = useState(player.balance || 0);
    const [isLocked, setIsLocked] = useState(false); // Pointer lock state
    const [virtualCursors, setVirtualCursors] = useState(0); // Track the number of virtual mice

    const [activeMice, setActiveMice] = useState({}); // Object to store each mouse's movement using custom ID
    const cursorRef = useRef(null);
    const panelRef = useRef(null);
    const [mouseIdCounter, setMouseIdCounter] = useState(0); // To assign unique ID to each mouse

    // Define unique colors for players
    const colorPalette = [
      "#FF6347", "#32CD32", "#1E90FF", "#FF1493", "#FFD700", "#8A2BE2", "#00CED1", "#FF4500", "#D2691E", "#FF69B4",
    ];
    const playerIdleColor = colorPalette[index % colorPalette.length];

    // Save bet amount to localStorage
    useEffect(() => {
      if (player.player_name) {
        localStorage.setItem(`betAmount_${player.player_name}`, betAmount);
      }
    }, [betAmount, player.player_name]);

    // Initialize and set up a checker for mouse position
    useEffect(() => {
      const handlePointerMove = (event) => {
        const { pointerId, clientX, clientY } = event;

        // Track mouse movement for each unique mouse ID
        setActiveMice((prevMice) => ({
          ...prevMice,
          [pointerId]: { id: pointerId, x: clientX, y: clientY }, // Store mouse position by unique id
        }));

        console.log(`Mouse moved (ID: ${pointerId}) at X: ${clientX}, Y: ${clientY}`);
      };

      // Attach pointer event listeners
      document.addEventListener("pointermove", handlePointerMove);

      // Clean up the event listener on unmount
      return () => {
        document.removeEventListener("pointermove", handlePointerMove);
      };
    }, []);

    // Handle pointer down for assigning a unique ID when the mouse first presses
    useEffect(() => {
      const handlePointerDown = (event) => {
        const { pointerId } = event;
        
        // Only assign a new ID if it's the first time the mouse is detected
        if (!activeMice[pointerId]) {
          setActiveMice((prevMice) => ({
            ...prevMice,
            [pointerId]: { id: mouseIdCounter, x: 0, y: 0 }, // Assign unique ID
          }));

          setMouseIdCounter((prev) => prev + 1); // Increment counter for the next mouse
        }
      };

      // Attach pointer event listeners
      document.addEventListener("pointerdown", handlePointerDown);

      // Cleanup the event listener on unmount
      return () => {
        document.removeEventListener("pointerdown", handlePointerDown);
      };
    }, [mouseIdCounter, activeMice]);

    // Calculate potential win
    const potentialWin = useMemo(() => {
      if (player.status === "Bet Placed" && player.betAmount) {
        return player.betAmount * multiplier;
      }
      return 0;
    }, [player.status, player.betAmount, multiplier]);

    // Update final win and balance after cashout
    useEffect(() => {
      if (player.status === "Cashed" && potentialWin > 0) {
        setFinalWin(potentialWin);
        setBalanceBeforeCashout(player.balance || 0);
      }
    }, [player.status, potentialWin, player.balance]);

    // Reset final win and balance on game restart
    useEffect(() => {
      if (resetGame) {
        setFinalWin(0);
        setBalanceBeforeCashout(player.balance || 0);
        setIsLocked(false);
        document.exitPointerLock();
      }
    }, [resetGame, player.balance]);

    // Handle pointer lock change
    useEffect(() => {
      const handlePointerLockChange = () => {
        const locked = document.pointerLockElement === panelRef.current;
        setIsLocked(locked);
        if (!locked) setActiveMouse(null); // Release active mouse when unlocked
      };

      document.addEventListener("pointerlockchange", handlePointerLockChange);
      return () => {
        document.removeEventListener("pointerlockchange", handlePointerLockChange);
      };
    }, [setActiveMouse]);
const App = () => {
  const [isLocked, setIsLocked] = useState(false);

  return (
    <div>
      <button onClick={() => setIsLocked(!isLocked)}>
        {isLocked ? "Unlock" : "Lock"}
      </button>
      <PointerMoveHandler isLocked={isLocked} />
    </div>
  );
};

    // Mouse movement tracking for the player panel
    const handleMouseMove = (e) => {
      if (isLocked && panelRef.current) {
        const panelBounds = panelRef.current.getBoundingClientRect();
        const mouseX = Math.max(0, Math.min(e.movementX, panelBounds.width));
        const mouseY = Math.max(0, Math.min(e.movementY, panelBounds.height));
        if (cursorRef.current) {
          cursorRef.current.style.left = `${mouseX}px`;
          cursorRef.current.style.top = `${mouseY}px`;
        }
      }
    };

    useEffect(() => {
      if (isLocked) {
        document.addEventListener("mousemove", handleMouseMove);
      }
      return () => {
        document.removeEventListener("mousemove", handleMouseMove);
      };
    }, [isLocked]);

    // Handle mouse wheel to adjust bet amount within levels (20, 40, 60, 100, 200)
    const handleMouseWheel = (e) => {
      e.preventDefault();
      const levelOptions = [10, 20, 40, 60, 100, 200];
      const currentIndex = levelOptions.indexOf(betAmount);
      let newIndex = e.deltaY > 0 ? currentIndex + 1 : currentIndex - 1;
      if (newIndex < 0) newIndex = 0;
      if (newIndex >= levelOptions.length) newIndex = levelOptions.length - 1;
      setBetAmount(levelOptions[newIndex]);
    };

    // Handle panel click for locking and placing bets
    const handlePanelClick = () => {
      if (player.status === "Idle" && gameStatus !== "Running") {
        placeBet(betAmount);
        setIsLocked(true);
        setMouseOwner(player.player_id);
        panelRef.current?.requestPointerLock();
      } else if (player.status === "Bet Placed") {
        cashOut();
      }
    };

    // Handle ESC key to release pointer lock
    const handleEscPress = (e) => {
      if (e.key === "Escape" && isLocked) {
        document.exitPointerLock();
      }
    };

    useEffect(() => {
      document.addEventListener("keydown", handleEscPress);
      return () => {
        document.removeEventListener("keydown", handleEscPress);
      };
    }, [isLocked]);

    // Determine the background color of the panel based on player status
    const getPanelColor = () => {
      if (player.status === "Bet Placed") return "#00BFFF";
      if (player.status === "Cashed") return "#32CD32";
      if (["Failed", "Crushed"].includes(player.status)) return "#FF0000";
      return playerIdleColor;
    };

    return (
      <div
        ref={panelRef}
        className="player-panel"
        style={{
          backgroundColor: getPanelColor(),
          color: "#fff",
          fontSize: "16px",
          padding: "12px",
          borderRadius: "8px",
          width: "250px",
          height: "200px",
          position: "relative",
          cursor: isLocked ? "none" : "default",
          overflow: "hidden",
        }}
        onClick={handlePanelClick}
        onWheel={handleMouseWheel}
      >
        {/* Player Name in Badge */}
        <div
          style={{
            position: "absolute",
            top: "15px",
            left: "15px", // Left margin alignment
            zIndex: 100,
            backgroundColor: "#FFD700", // Golden badge background
            color: "#000", // Contrasting text color
            fontSize: "18px", // Font size for visibility
            fontWeight: "bold",
            padding: "5px 10px", // Padding inside the badge
            borderRadius: "12px", // Rounded badge shape
            boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.7)", // Shadow for depth
            fontFamily: "'Arial Black', sans-serif", // Strong font style
            textAlign: "center", // Center the text inside the badge
            whiteSpace: "nowrap", // Prevent text wrapping
          }}
        >
          {player.player_name || "Player"}
        </div>
    
        {/* Mouse Icon */}
        <div
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            fontSize: "20px",
            color: isLocked ? "#FFD700" : "#fff",
            zIndex: 10,
          }}
        >
          <FaMouse />
        </div>
    
        {/* Virtual Cursor */}
        {isLocked && (
          <div
            ref={cursorRef}
            style={{
              position: "absolute",
              bottom: "10px",
              width: "20px",
              height: "20px",
              backgroundColor: "#FFD700",
              borderRadius: "50%",
              pointerEvents: "none",
            }}
          />
        )}
    
        {/* Bet Level Dropdown */}
        <div
          style={{
            marginBottom: "20px",
            textAlign: "center",
            color: "#FFD700",
            fontFamily: "'Arial Black', sans-serif",
            textShadow: "2px 2px 5px rgba(0, 0, 0, 0.7)",
            fontSize: "18px",
          }}
        >
          <label
            htmlFor="betLevel"
            style={{
              display: "block",
              marginBottom: "10px",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Level:
          </label>
          <select
            id="betLevel"
            value={betAmount}
            onChange={(e) => setBetAmount(parseInt(e.target.value, 10))}
            style={{
              width: "100%",
              padding: "12px 10px",
              borderRadius: "10px",
              border: "2px solid #FFD700",
              backgroundColor: "#222",
              color: "#FFD700",
              fontSize: "18px",
              fontFamily: "'Arial', sans-serif",
              cursor: "pointer",
              textAlign: "center",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.7)",
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={40}>40</option>
            <option value={60}>60</option>
            <option value={100}>100</option>
            <option value={200}>200</option>
          </select>
        </div>
    
        {/* Balance and Potential Win Section */}
        <div
          style={{
            position: "absolute",
            bottom: "15px",
            left: "15px",
            zIndex: 50,
            color: "#000000",
            fontFamily: "'Arial Black', sans-serif",
            textShadow: "2px 2px 10px rgba(0, 0, 0, 0.7)",
            fontSize: "18px",
            lineHeight: "1.5",
          }}
        >
          <p style={{ margin: 0 }}>
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>Bal:</span>
             {parseFloat(balanceBeforeCashout + finalWin).toFixed(2)}
          </p>
          <p style={{ margin: 0 }}>
            <span style={{ fontWeight: "bold", fontSize: "20px" }}>Win:</span>
           {potentialWin.toFixed(2)}
          </p>
        </div>
    
        {/* Debugging: Display number of active virtual cursors */}
        <div
  style={{
    position: "absolute",
    bottom: "0", // Position it directly at the bottom of the container
    left: "10px", // Move it to the left
    fontSize: "8px",
    color: "#fff",
  }}
>
  Players: {Object.keys(activeMice).length}
</div>


      </div>
    );
    
  }
);

export default PlayerPanel;
