import React, { useEffect, useRef, useState } from "react";

const PointerMoveHandler = ({ isLocked }) => {
  const panelRef = useRef(null);
  const cursorRef = useRef(null);
  const [locked, setLocked] = useState(isLocked); // State to manage lock status

  useEffect(() => {
    const handlePointerLockChange = () => {
      const isPointerLocked = document.pointerLockElement === panelRef.current;
      setLocked(isPointerLocked);
    };

    const handlePointerLockError = () => {
      console.error("Pointer lock failed.");
    };

    // Event listeners for pointer lock
    document.addEventListener("pointerlockchange", handlePointerLockChange);
    document.addEventListener("pointerlockerror", handlePointerLockError);

    return () => {
      document.removeEventListener("pointerlockchange", handlePointerLockChange);
      document.removeEventListener("pointerlockerror", handlePointerLockError);
    };
  }, []);

  const handlePanelClick = () => {
    if (panelRef.current && !locked) {
      panelRef.current.requestPointerLock();
    }
  };

  const handlePointerMove = (event) => {
    if (locked && cursorRef.current && panelRef.current) {
      const movementX = event.movementX || 0;
      const movementY = event.movementY || 0;

      const panelBounds = panelRef.current.getBoundingClientRect();
      const cursorStyle = window.getComputedStyle(cursorRef.current);
      const currentX = parseFloat(cursorStyle.left) || 0;
      const currentY = parseFloat(cursorStyle.top) || 0;

      let newX = currentX + movementX;
      let newY = currentY + movementY;

      // Restrict to panel bounds
      newX = Math.max(0, Math.min(newX, panelBounds.width - 10));
      newY = Math.max(0, Math.min(newY, panelBounds.height - 10));

      cursorRef.current.style.left = `${newX}px`;
      cursorRef.current.style.top = `${newY}px`;
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape" && locked) {
      document.exitPointerLock();
    }
  };

  useEffect(() => {
    if (locked) {
      document.addEventListener("mousemove", handlePointerMove);
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("mousemove", handlePointerMove);
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousemove", handlePointerMove);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [locked]);

  return (
    <div
      ref={panelRef}
      onClick={handlePanelClick}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        border: "1px solid black",
        overflow: "hidden",
        cursor: locked ? "none" : "default",
      }}
    >
      <div
        ref={cursorRef}
        style={{
          position: "absolute",
          width: "10px",
          height: "10px",
          backgroundColor: "red",
          borderRadius: "50%",
          pointerEvents: "none",
          top: "50%",
          left: "50%",
        }}
      />
      <p>Click inside the panel to lock the cursor. Press "Escape" to unlock.</p>
    </div>
  );
};

export default PointerMoveHandler;
