import React, { useEffect, useState, useRef } from 'react';
import './GameCanvas.css';

const GameCanvas = ({ multiplier, isCrashed, countdown, stake }) => {
    const canvasRef = useRef(null);
    const [crashedTextVisible, setCrashedTextVisible] = useState(false);
    const [graphXOffset, setGraphXOffset] = useState(0);
    const [fallPosition, setFallPosition] = useState({ x: null, y: null });
    const [explosionActive, setExplosionActive] = useState(false);
    const [backgroundAnimationOffset, setBackgroundAnimationOffset] = useState(0);
    const [oscillationPhase, setOscillationPhase] = useState(0);

    useEffect(() => {
        if (isCrashed) {
            setCrashedTextVisible(true);

            const canvas = canvasRef.current;
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;

            const maxX = 500;
            const maxY = 10;

            const scale = (value, max, canvasSize) => Math.min((value / max) * canvasSize, canvasSize - 50);
            const x = scale(graphXOffset, maxX, canvasWidth - 60) + 30;
            const y = canvasHeight - 30 - scale(multiplier, maxY, canvasHeight - 60);

            setFallPosition({ x, y });

            const fallInterval = setInterval(() => {
                setFallPosition((prev) => {
                    if (!prev || prev.y >= canvasHeight - 30) {
                        clearInterval(fallInterval);
                        setExplosionActive(true);
                        setTimeout(() => {
                            setExplosionActive(false);
                            setCrashedTextVisible(false);
                            setGraphXOffset(0);
                            setFallPosition({ x: null, y: null });
                        }, 1000);
                        return prev;
                    }
                    return { x: prev.x, y: prev.y + 15 };
                });
            }, 50);
        }
    }, [isCrashed]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;

        const scale = (value, max, canvasSize) => Math.min((value / max) * canvasSize, canvasSize - 50);

        const render = () => {
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);
            ctx.save();

            // === Background Animation ===
            ctx.fillStyle = 'black';
            ctx.fillRect(0, 0, canvasWidth, canvasHeight);

            for (let i = -canvasWidth; i < canvasWidth * 2; i += 50) {
                ctx.beginPath();
                ctx.moveTo(i + backgroundAnimationOffset, 0);
                ctx.lineTo(i - canvasHeight + backgroundAnimationOffset, canvasHeight);
                ctx.strokeStyle = 'rgba(255, 255, 255, 0.1)';
                ctx.lineWidth = 2;
                ctx.stroke();
            }

            if (!isCrashed) {
                setBackgroundAnimationOffset((prevOffset) => (prevOffset + 2) % 50);
            }

            // === Game Elements ===
            ctx.beginPath();
            ctx.moveTo(30, canvasHeight - 30);
            ctx.lineTo(canvasWidth - 30, canvasHeight - 30);
            ctx.strokeStyle = '#fff';
            ctx.lineWidth = 2;
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(30, 30);
            ctx.lineTo(30, canvasHeight - 30);
            ctx.strokeStyle = '#fff';
            ctx.lineWidth = 2;
            ctx.stroke();

            const maxX = 500;
            const maxY = 10;

            let xPos = scale(graphXOffset, maxX, canvasWidth - 60) + 30;
            let yPos = canvasHeight - 30 - scale(multiplier, maxY, canvasHeight - 60);

            if (multiplier >= 9 && !isCrashed) {
                yPos += Math.sin(oscillationPhase) * 10; // Oscillation
                setOscillationPhase((prevPhase) => prevPhase + 0.2);
            }

            if (isCrashed && fallPosition.y !== null) {
                yPos = fallPosition.y;
                xPos = fallPosition.x;
            }

            // Draw the flight path
            if (!isCrashed) {
                ctx.beginPath();
                ctx.moveTo(30, canvasHeight - 30);
                ctx.lineTo(xPos, yPos);
                ctx.lineTo(xPos, canvasHeight - 30);
                ctx.closePath();
                ctx.fillStyle = 'rgba(162, 202, 18, 0.3)';
                ctx.fill();
            }

            // Draw the projectile
            if (!explosionActive) {
                ctx.beginPath();
                ctx.arc(xPos, yPos, 5, 0, Math.PI * 2);
                ctx.fillStyle = isCrashed ? 'red' : 'blue';
                ctx.fill();
            }

            // Explosion effect
            if (explosionActive) {
                const explosionRadius = 30;
                const centerX = fallPosition.x;
                const centerY = canvasHeight - 30;

                for (let i = 0; i < 20; i++) {
                    const angle = (Math.PI * 2 * i) / 20;
                    const explosionX = centerX + Math.cos(angle) * explosionRadius;
                    const explosionY = centerY + Math.sin(angle) * explosionRadius;

                    ctx.beginPath();
                    ctx.arc(explosionX, explosionY, 5, 0, Math.PI * 2);
                    ctx.fillStyle = `rgba(255, ${Math.random() * 255}, 0, 0.9)`;
                    ctx.fill();
                }
            }

            if (!isCrashed) {
                ctx.font = '16px Arial';
                ctx.fillStyle = 'blue';
                ctx.fillText(multiplier.toFixed(2) + 'x', xPos + 10, yPos - 10);

                setGraphXOffset((prevOffset) => (prevOffset >= maxX ? maxX : prevOffset + 2));
            }

            if (crashedTextVisible) {
                ctx.fillStyle = 'red';
                ctx.font = 'bold 42px Arial';
                ctx.textAlign = 'center';
                ctx.fillText('CRASHED!', canvasWidth / 2, canvasHeight / 2 - 10);

                ctx.font = 'bold 28px Arial';
                ctx.fillText(`${multiplier.toFixed(2)}x`, canvasWidth / 2, canvasHeight / 2 + 30);
            } else if (countdown > 0) {
                ctx.fillStyle = 'yellow';
                ctx.font = 'bold 42px Arial';
                ctx.textAlign = 'center';
                ctx.fillText(`Starting in: ${countdown}s`, canvasWidth / 2, canvasHeight / 2 + 10);

                const radius = 40;
                const endAngle = (Math.PI * 2 * (5 - countdown)) / 5;
                ctx.beginPath();
                ctx.arc(canvasWidth / 2, canvasHeight / 2 + 60, radius, 0, endAngle);
                ctx.strokeStyle = 'blue';
                ctx.lineWidth = 8;
                ctx.stroke();
            }

            ctx.restore();
        };

        const interval = setInterval(render, 50);

        return () => clearInterval(interval);
    }, [
        multiplier,
        isCrashed,
        countdown,
        crashedTextVisible,
        graphXOffset,
        fallPosition,
        explosionActive,
        backgroundAnimationOffset,
        oscillationPhase,
    ]);

    return (
        <canvas
            ref={canvasRef}
            width="850"
            height="450"
            style={{
                margin: '0px auto',
                borderRadius: '0px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                display: 'block',
            }}
        ></canvas>
    );
};

export default GameCanvas;
