import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import GameCanvas from './GameCanvas';
import Sidebar from './Sidebar';
import PlayerManager from './PlayerManager';
import NavBar from './NavBar';
import Preloader from './Preloader';
import { FaSignOutAlt } from 'react-icons/fa';
import './App.css';

const socket = io('https://rocketaviator.com'); // Ensure HTTPS is used

const AviatorGame = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const [multiplier, setMultiplier] = useState(1.0);
  const [isCrashed, setIsCrashed] = useState(false);
  const [isGameRunning, setIsGameRunning] = useState(false);
  const [countdown, setCountdown] = useState(15);
  const [history, setHistory] = useState([]); // Store game history
  const [playerActions, setPlayerActions] = useState([]); // Store player actions for the sidebar
  const [virtualCursors, setVirtualCursors] = useState(0); // Track the number of virtual mice
  const [isPreloading, setIsPreloading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPreloading(false);
    }, 5000); // 5 seconds wait time

    return () => clearTimeout(timer);
  }, []);

  const mouseMoveHandler = (e) => {
    // Increase the number of virtual mice whenever mouse movement is detected
    setVirtualCursors((prevCount) => prevCount + 1);
    console.log("Mouse moved:", e); // Log for debugging
  };

  const mouseUpHandler = (e) => {
    console.log("Mouse released:", e);
  };

  const mouseDownHandler = (e) => {
    console.log("Mouse pressed:", e);
  };

  useEffect(() => {
    // Setup mouse event listeners
    document.addEventListener("pointermove", mouseMoveHandler);
    document.addEventListener("pointerdown", mouseDownHandler);
    document.addEventListener("pointerup", mouseUpHandler);

    return () => {
      // Cleanup listeners
      document.removeEventListener("pointermove", mouseMoveHandler);
      document.removeEventListener("pointerdown", mouseDownHandler);
      document.removeEventListener("pointerup", mouseUpHandler);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    // Socket event listeners
    socket.on('countdownUpdate', (timeLeft) => {
      setCountdown(timeLeft);
      setIsGameRunning(false);
    });

    socket.on('multiplierUpdate', (newMultiplier) => {
      setMultiplier(newMultiplier);
      setIsGameRunning(true);
      setIsCrashed(false);
      setCountdown(null);
    });

    socket.on('gameCrashed', (crashMultiplier) => {
      setIsCrashed(true);
      setIsGameRunning(false);
      setHistory((prevHistory) => [crashMultiplier, ...prevHistory.slice(0, 19)]);
      setTimeout(() => {
        setPlayerActions([]); // Clear player actions after crash
      }, 1000); // Wait for 1 second before resetting
    });

    socket.on('playerAction', (action) => {
      setPlayerActions((prevActions) => [action, ...prevActions].slice(0, 10)); // Keep the last 10 actions
    });

    return () => {
      socket.off('countdownUpdate');
      socket.off('multiplierUpdate');
      socket.off('gameCrashed');
      socket.off('playerAction');
    };
  }, []);

  
  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  // Handle player actions from PlayerManager and pass to backend
  const handlePlayerAction = (action) => {
    console.log('Player action:', action);

    // Emit player action to backend
    if (action.type === 'placeBet') {
      socket.emit('placeBet', action);
    } else if (action.type === 'cashOut') {
      socket.emit('cashOut', action);
    }

    // Update the player actions in the state for Sidebar
    setPlayerActions((prevActions) => [action, ...prevActions].slice(0, 10)); // Keep the last 10 actions
  };

  if (isPreloading) {
    return <Preloader />;
  }

  return (
    <div className="app-container">
      

      {/* Floating logout button with icon */}
      <button onClick={handleLogout} className="logout-button">
        <FaSignOutAlt size={24} />
      </button>

      {/* NavBar with dynamic content (multiplier, countdown, and history) */}
      <NavBar multiplier={multiplier} countdown={countdown} history={history} />

      <div className="main-content">
        <Sidebar playerActions={playerActions} />
        <div className="game-section">
          <GameCanvas multiplier={multiplier} isCrashed={isCrashed} />
          <div className="game-status">
  {isGameRunning ? (
    <p className="game-status-text game-status-multiplier">
      {multiplier.toFixed(2)}x
    </p>
  ) : (
    <div className="countdown-display">
      <p className="game-status-text countdown-text">
        Next in: {countdown}s
      </p>
      <div className="countdown-loader"></div>
    </div>
  )}
</div>

          <PlayerManager
            multiplier={multiplier}
            gameStatus={isGameRunning ? 'Playing' : 'Idle'}
            countdown={countdown}
            handlePlayerAction={handlePlayerAction}  // Pass down the handler function
          />
        </div>
      </div>

      {/* Debugging: Display number of active virtual cursors */}
      <div style={{ position: "absolute", top: "10px", right: "10px", color: "white" }}>
        Active Mice: {virtualCursors}
      </div>
    </div>
  );
};

export default AviatorGame;
